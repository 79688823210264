import Cookies from 'js-cookie';
import { ONE_YEAR } from 'constants/time';
import { SESSION_INFO_API } from 'constants/routes';
import {
  PODCASTER_SESSION_ID,
  SESSION_ID,
  UNIQUE_BROWSER_ID,
  SESSION_TOKEN,
} from 'constants/cookies';
import { call } from 'redux-saga/effects';
import { postWithCredentials } from '../api';

export function* updateSession(): any {
  const sessionId = Cookies.get(SESSION_ID);
  const uniqueBrowserId = Cookies.get(UNIQUE_BROWSER_ID);

  if (!sessionId || !uniqueBrowserId) {
    const response = yield call(postWithCredentials, {
      body: {},
      path: SESSION_INFO_API,
    });

    const json = yield call([response, 'json']);

    handleSessionInResponse(
      json.sessionId,
      json.uniqueBrowserId,
      json.sessionToken
    );
  }
}

export function handleSessionInResponse(
  sessionId: string,
  uniqueBrowserId: string,
  sessionToken: string | null
) {
  setSessionCookie(SESSION_ID, sessionId);
  setSessionCookie(UNIQUE_BROWSER_ID, uniqueBrowserId);
  setSessionCookie(SESSION_TOKEN, sessionToken);

  const podcasterSessionId = Cookies.get(PODCASTER_SESSION_ID);

  if (!podcasterSessionId && sessionId) {
    setSessionCookie(PODCASTER_SESSION_ID, sessionId);
  }
}

function setSessionCookie(name: string, value: string | null) {
  const oneYearFromNow = Date.now() + ONE_YEAR;

  if (value) {
    Cookies.set(name, value, {
      domain: '.amazon.com',
      expires: new Date(oneYearFromNow),
      secure: true,
    });
  }
}
